import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import ImgSelbstvertrauen from './images/selbstvertrauen';
import ImgDerWeg from './images/der_weg_ist_das_ziel';
import Image from './../components/image';
import Caption from './../components/caption';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Krisen und Lebensumbrüche meistern`}</h1>
    <div className="columns page-section">
      <div className="column is-three-fifths">
        <h2>{`Meine Grundeinstellung zu Krisen im Leben`}</h2>
        <p>{`Ich glaube, dass das innere Ziel eines jeden Menschen die produktive Entfaltung seiner eigenen Fähigkeiten und Talente ist.`}</p>
        <p>{`Unsere Gesellschaft ist eine Haben-Gesellschaft, die vom Auto bis zu den eigenen Krankheiten alles besitzt als etwas äußeres, getrennt von sich selbst und von dem man glaubt es halten zu können oder zu müssen. So hält man auch an Gewohnheiten, Verhaltensweisen, Arbeit, Beziehung und Besitztum fest, selbst wenn man daran erkrankt, leidet und unzufrieden wird.`}</p>
        <p><strong parentName="p">{`Wer das macht, was er gerne macht und es so macht wie nur er es machen könnte aus seinem Charakter und seiner Biographie heraus, bei dem stellt sich automatisch – wie eine Begleiterscheinung – Freude ein.`}</strong></p>
      </div>
      <div className="column has-text-centered image-column">
    
    <figure>
        <Image filename="krisen-neu-01.jpg" style={{
            maxWidth: "270px",
            margin: "0 auto"
          }} imageStyle={{
            objectFit: "contain"
          }} alt="Endlich angekommen im wahren Selbst. Ein Ende – ein Neubeginn." mdxType="Image" />
        <Caption style={{
            maxWidth: "270px"
          }} mdxType="Caption">
            Endlich angekommen im wahren Selbst. Ein Ende – ein Neubeginn.
        </Caption>
    </figure>
      </div>
    </div>
    <div className="columns page-section">
      <div className="column has-text-centered image-column">
    <figure>
        <Image filename="krisen-neu-02.jpg" style={{
            maxWidth: "270px",
            margin: "0 auto"
          }} imageStyle={{
            objectFit: "contain"
          }} alt="" mdxType="Image" />
    </figure>
      </div>
      <div className="column is-two-thirds">
        <p>{`Das Selbst im Menschen sorgt für die so wichtige Krise, die die Chance in sich birgt „umzukehren“ auf den eigentlichen Weg, auf den Weg eigener Talente, Produktivität und Freude.`}</p>
        <p>{`Der Weg ist lang, voller Kurven, Überraschungen, Enttäuschungen, Hoffnungen, Absturz, Trauer, Freude und Erfüllung.`}</p>
        <p><strong parentName="p">{`Eine kreative Begleitung unterstützt diesen Weg, gibt Mut ihn zu wagen und durchzutragen.`}</strong></p>
      </div>
    </div>
    <div className="columns page-section">
      <div className="column is-three-fifths">
        <h2>{`Krisen als Chance erkennen und meistern`}</h2>
        <p>{`Verena Kast beschreibt die Krise als den möglichen schöpferischen Sprung. Hier kann der Schritt in das Neue Verhalten und Sein gelingen und das Alte abgeworfen werden, das seinen Dienst getan hat, aber nun nicht mehr gebraucht wird und seinen Zweck erfüllt hat.`}</p>
        <p><strong parentName="p">{`Ohne Krise geht es nicht`}</strong>{`, weil das noch nicht Bekannte eine gewaltige Zugkraft hat und wie ein Magnet uns anzieht, auf der anderen Seite der Schritt aber ins Ungewisse meist Angst auslöst, weil wir den verlässlichen und sicheren Hafen verlassen müssen.`}</p>
        <p><strong parentName="p">{`Der Sprung in die neuen Talente`}</strong>{`, Verhaltens-, Sicht- und Seinsweisen hinein ist ein zutiefst lebendiger Prozess und er bedeutet eine imense Aus-ein-ander-setzung mit sich selbst und seiner Umwelt.`}</p>
        <p><strong parentName="p">{`Bildgebende Methoden`}</strong>{` docken direkt am Unterbewussten an und ermöglichen das Sichtbarmachen von Verstrickungen, nicht bewußten Verhaltensmustern und Glaubenssätzen, die aufgelöst werden können, so sie erkannt sind; genauso werden Ressourcen und Talente erfahrbar, erlebbar und integriert.`}</p>
      </div>
      <div className="column has-text-centered image-column">
    
        <figure>
    <Image filename="hohe-see.jpg" imageStyle={{
            objectFit: "contain"
          }} alt="Gemälde" mdxType="Image" />
    <Caption mdxType="Caption">
        Auf hoher See, in der Krise, sieht man kein Land und ist dem Sturm ausgesetzt. Aber dein Schiff schwimmt, du bist begleitet und im Morgengrauen erreichst du den Hafen, nachdem der Sturm abgeklungen ist. Navigation – Methoden - helfen dabei.
    </Caption>
        </figure>
      </div>
    </div>
    <div className="page-section">
      <h2>{`Was Begleitung auf diesem Weg bringen kann`}</h2>
      <div className="columns">
        <div className="column is-half list-column">
          <ul>
            <li parentName="ul"><strong parentName="li">{`Zuversicht`}</strong>{`, dass das ganze einen Sinn hat und es sich lohnt es durchzutragen`}</li>
            <li parentName="ul"><strong parentName="li">{`Sicherheit`}</strong>{` durch bildhafte Intervensionstechniken in akuten Phasen`}</li>
            <li parentName="ul">{`Das Ermöglichen `}<strong parentName="li">{`neuer Sichtweisen`}</strong>{` auf die sich verändernden Lebensumstände durch verschiedene Methoden aus der Mal- und Gestaltungstherapie, der Ritualarbeit und Kontemplation`}</li>
          </ul>
        </div>
        <div className="column is-half list-column">
          <ul>
            <li parentName="ul"><strong parentName="li">{`Stabilisierung`}</strong>{` im gesamten Verlauf der emotionalen Auf- und Abbewegung`}</li>
            <li parentName="ul">{`Die `}<strong parentName="li">{`Lösung`}</strong>{`, die im Unterbewussten ja schon vorhanden ist kleinen möglichen Schritten an die Oberfläche bringen und in die Handlungsebene integrieren`}</li>
            <li parentName="ul"><strong parentName="li">{`Ressourcen und Talente`}</strong>{`, die bis jetzt ungenutzt oder unerkannt waren ans Licht bringen, stärken und integrieren`}</li>
          </ul>
        </div>
      </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      